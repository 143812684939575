<template>
  <div class="register-body">
    <div class="register-div">
      <el-row>
        <el-col style="text-align: center; padding: 25px 0 25px 0">
          <i class="iconfont icon-r-building" style="font-size: 36px"></i>
          <b style="font-size: 36px"> 用户注册</b>
        </el-col>
      </el-row>
      <el-form :model="registerForm" :rules="rules" ref="registerForm" label-width="100px" >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="registerForm.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="registerForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码"  prop="confirmPassword">
          <el-input type="password" v-model="registerForm.confirmPassword" placeholder="请确认密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="registerForm.agree">我已阅读并同意条款</el-checkbox>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="submitRegister" style="font-size: 22px">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      registerForm: {
        no:'',
        name: '',
        password: '',
        confirmPassword: '',
        agree: false
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入你的姓名', trigger: 'blur' },
          {
            pattern: /^[\u4e00-\u9fa5]{2,5}$/,
            message: '姓名只能为2到5个中文字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于 6 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
        ]
      }
    };
  },
  methods: {
    submitRegister() {
      if (!this.registerForm.name || !this.registerForm.password) {
        this.$message.error('用户名和密码不能为空');
        return;
      }
      if (this.registerForm.password !== this.registerForm.confirmPassword) {
        this.$message.error('两次密码输入不一致');
        return;
      }
      if (!this.registerForm.agree) {
        this.$message.error('请同意条款');
        return;
      }
      // 模拟注册请求
      this.registerForm.no=this.registerForm.name;
      this.$axios
          .post(this.$httpUrl + "/user/save", this.registerForm)
          .then((res) => res.data)
          .then((res) => {
            if (res.code == 200) {
              alert("注册成功");
              //跳转到主页t
              this.$message.success('注册成功');
              this.$router.push("/");

            } else {
              alert("注册失败，用户可能存在！");
              return false;
            }
          })
          .catch((e) => {
            this.confirm_disabled = false;
            console.log(e);
            if (
                e.response == undefined ||
                e.response.data == undefined
            ) {
              this.$message({
                showClose: true,
                message: e,
                type: "error",
                duration: 5000,
              });
            } else {
              this.$message({
                showClose: true,
                message: e.response.data,
                type: "error",
                duration: 5000,
              });
            }
          });


      // 这里可以添加跳转到登录页面的逻辑
      this.$router.push('/login');  // 假设你的登录页面路径为 '/login'
    }
  }
};
</script>

<style scoped>
.register-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}
.register-div {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
}
</style>
